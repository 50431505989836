<script>
import {
  getKnowledgeBaseItem,
  getKnowledgeBaseItemMarkdown,
} from '@/services/api/knowledge-base.api';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';

export default {
  name: 'KnowledgeBaseItem',
  components: { AntLoading },
  data: () => {
    return {
      item: null,
      markdown: null,
      isLoading: false,
      hasMarkdown: false,
    };
  },
  computed: {
    ...mapGetters(['selectedLicense']),
  },
  watch: {
    '$route.params.itemId': {
      handler: async function (value) {
        this.item = null;
        this.markdown = null;
        this.hasMarkdown = false;
        this.isLoading = false;

        if (value) {
          this.isLoading = true;
          this.item = await getKnowledgeBaseItem(
            this.selectedLicense.id,
            value
          );
          this.hasMarkdown = false;

          if (this.item.markdown) {
            this.markdown = await getKnowledgeBaseItemMarkdown(
              this.selectedLicense.id,
              this.item.id,
              this.item.markdown.id
            );
            this.hasMarkdown = true;
          }

          this.isLoading = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <div v-if="isLoading" class="d-flex align-center justify-center flex-1">
    <ant-loading />
  </div>
  <div
    v-else
    class="py-10 px-15 overflow-y-auto background-white"
    style="
      margin: 50px 200px 0;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    "
  >
    <vue-markdown v-if="hasMarkdown" :source="markdown" />
    <div v-else class="d-flex align-center justify-center flex-1 full-height">
      No markdown file has been found
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
